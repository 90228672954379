<template>
  <claim-tasks-layout>
    <b-tabs content-class="mt-3">
      <b-tab
      v-for="(tab, index) in tabs"
      :key="tab.key"
      :title="tab.title"
      :active="selectedTab === index"
      @click="selectTab(index)">
        <component
        v-if="selectedTab === index"
        :is="tab.component"
        :ref="tab.key"/>
      </b-tab>
    </b-tabs>
  </claim-tasks-layout>
</template>

<script>
import translations from '@/translations';
import ClaimTasksLayout from '@/components/FinanceOperations/ClaimTasksLayout.vue';
import PendingApprovalList from './pendingList.vue';
import ApprovedList from './approvedList.vue';

export default {
  name: 'ClaimWriteOffTasks',
  components: {
    ClaimTasksLayout,
    PendingApprovalList,
    ApprovedList,
  },
  data() {
    return {
      translations: translations.finance_operations.claim_write_off_tasks,
      selectedTab: 0,
      tabs: [
        {
          key: 'pending_approval',
          title: translations.finance_operations.claim_write_off_tasks.tabs.pending_approval,
          component: 'PendingApprovalList',
        },
        {
          key: 'approved',
          title: translations.finance_operations.claim_write_off_tasks.tabs.approved,
          component: 'ApprovedList',
        },
      ],
    };
  },
  async beforeMount() {
    // persist selected tab
    const tab = this.$route.query?.tab || 'pending_approval';
    const tabIndex = this.tabs.findIndex(t => t.key === tab);
    if (tabIndex !== -1) this.selectedTab = tabIndex;
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;

      // reset query params when changing tabs
      const currentRoute = this.$route;
      this.$router.replace({
        path: currentRoute.path,
        query: {
          tab: this.tabs[index].key,
        },
      }).catch(() => {});
    },
  },
};
</script>
