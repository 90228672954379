<template>
  <financial-list
  v-model="currentPage"
  :header-data="tableHeaders"
  :events="TABLE_EVENTS"
  :data="list"
  :disabled="loading"
  :items-per-page="itemsPerPage"
  :total="total"
  :no-items-message="translations.list.empty"
  itemRefKey="id"
  @page-changed="fetchList"
  @row-details="handleDetails"/>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/finance-service';
import translations from '@/translations';
import { formatDate, formatDatetime, formatValue } from '@/helpers/finance';
import { CLAIM_WRITE_OFF_TASK_REASONS } from '@/constants/financeOperations';


export default {
  name: 'ApprovedList',
  components: {
    FinancialList,
  },
  created() {
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.list.view_details,
        icon: 'eye',
      },
    };
  },
  computed: {
    tableHeaders() {
      return [
        associateHeaderDataItem(this.translations.list.claim_external_id, 'claim_unique_key'),
        associateHeaderDataItem(this.translations.list.client_name, 'client_name'),
        associateHeaderDataItem(this.translations.list.payer_name, 'payer_name'),
        associateHeaderDataItem(this.translations.list.assignee, 'assignee'),
        associateHeaderDataItem(this.translations.list.claim_submitted_date, 'claim_submitted_date'),
        associateHeaderDataItem(this.translations.list.billed_amount, 'billed_amount'),
        associateHeaderDataItem(this.translations.list.paid_amount, 'paid_amount'),
        associateHeaderDataItem(this.translations.list.write_off_amount, 'amount'),
        associateHeaderDataItem(this.translations.list.reason, 'reason'),
        associateHeaderDataItem(this.translations.list.approved_date, 'approved_date'),
      ];
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claim_write_off_tasks,
      loading: true,
      list: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.total = page * this.itemsPerPage;
    await this.fetchList(page);
  },
  methods: {
    async fetchList(page) {
      this.loading = true;
      this.currentPage = page;

      const offset = (page - 1) * this.itemsPerPage;

      try {
        const { claim_write_off_tasks: claimsWOT, total } = await service.getClaimWriteOffTasks({
          status: 'approved',
          order_by: 'approved_date',
          order_descending: true,
          offset,
          limit: this.itemsPerPage,
        });

        this.list = claimsWOT.map(cwot => {
          const claimFUT = cwot.claim_follow_up_task;

          return {
            ...cwot,
            claim_unique_key: claimFUT.claim.unique_key,
            client_name: claimFUT.claim_account.client_name,
            payer_name: claimFUT.claim_account.payer_name,
            claim_submitted_date: formatDate(claimFUT.claim.submitted_date),
            billed_amount: formatValue(cwot.billed_amount),
            paid_amount: formatValue(cwot.paid_amount),
            amount: formatValue(cwot.amount),
            reason: CLAIM_WRITE_OFF_TASK_REASONS[cwot.reason],
            approved_date: formatDatetime(cwot.approved_date),
          };
        });
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    handleDetails(id) {
      this.$router.push(`/finance-operations/claim-write-off-tasks/${id}`);
    },
  },
};
</script>
